import React from "react"
import { graphql } from "gatsby"
import { InnerPageNavigation } from "../components/Navigation"
import { Layout } from "../components/Layout"
import { MDXRenderer } from "gatsby-plugin-mdx"
import {
  GatsbyImage as Img,
  getImage,
  ImageDataLike,
} from "gatsby-plugin-image"
import { SidebarItem } from "./types"

interface PageWithTitleDescriptionTemplateProps {
  title: string
  description: string
  namespace: string
  sidebar: {
    title: string
    items: SidebarItem[]
  }
  sideart: ImageDataLike
  body: string
}

export const PageWithTitleDescriptionTemplate: React.FC<
  PageWithTitleDescriptionTemplateProps
> = ({ title, description, body, namespace, sidebar, sideart }) => {
  const sideArtImage = getImage(sideart)
  return (
    <Layout title={title} description={description.slice(0, 157) + "..."}>
      <InnerPageNavigation />
      <section className="title-section">
        <div className="container relative px-4 pt-8 pb-2 mx-auto lg:px-12">
          {sideArtImage ? (
            <div className="absolute bottom-0 left-0 hidden -mb-32 xl:block xl:-ml-28">
              <div className="flex-shrink-0 w-28">
                <Img image={sideArtImage} alt="" />
              </div>
            </div>
          ) : null}
          <h1 className="text:3xl sm:text-4xl md:text-4.5xl font-serif mb-3 text-dark-burgundy">
            {title}
          </h1>
          <p className="sm:w-10/12">{description}</p>
        </div>
      </section>
      {sidebar ? (
        <aside className="absolute right-0 hidden mt-4 mr-24 xl:mr-64 sidebar lg:block">
          <h2 className="border-b">{sidebar.title}</h2>
          <ul className="mt-4 list-none">
            {sidebar.items.map((item: SidebarItem) => (
              <li key={item.title} className="ml-2">
                <h3 className="font-sans text-base italic">{item.title}</h3>
                <span className="block">{item.date}</span>
                <span className="block">{item.time}</span>
              </li>
            ))}
          </ul>
        </aside>
      ) : null}
      <div
        className={`container flex-grow px-4 lg:px-12 mx-auto mt-10 mb-32 innerpage relative ${namespace}`}
      >
        <div className="md:max-w-xl xl:max-w-3xl">
          <MDXRenderer className={namespace}>{body}</MDXRenderer>
        </div>
      </div>
    </Layout>
  )
}

interface PageWithTitleDescriptionProps {
  data: {
    mdx: {
      frontmatter: {
        title: string
        description: string
        namespace: string
        sidebar: {
          title: string
          items: SidebarItem[]
        }
        sideart: ImageDataLike
      }
      body: string
    }
  }
}

const PageWithTitleDescription: React.FC<PageWithTitleDescriptionProps> = ({
  data,
}) => {
  const { frontmatter, body } = data.mdx
  return (
    <PageWithTitleDescriptionTemplate
      title={frontmatter.title}
      description={frontmatter.description}
      body={body}
      sidebar={frontmatter.sidebar}
      namespace={frontmatter.namespace}
      sideart={frontmatter.sideart}
    />
  )
}

export default PageWithTitleDescription

export const PageWithTitleDescriptionQuery = graphql`
  query PageWithTitleDescriptionByID($id: String!) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        title
        description
        namespace
        sidebar {
          title
          items {
            title
            date
            time
          }
        }
        sideart {
          childImageSharp {
            gatsbyImageData(
              width: 400
              placeholder: BLURRED
              formats: [AUTO, AVIF, WEBP]
            )
          }
        }
      }
    }
  }
`
